import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Redirect,
} from "react-router-dom";

// Components
import matches from "./pages/matches";
import standings from "./pages/standings";
import landing from "./pages/landing";

import logo from "./util/5-STAR_Golf.png";

function App() {
  return (
    <Router>
      <div className="container">
        <div className="header-container">
          <img src={logo} className="header-logo" alt="5-STAR Golf" />
          {/* nav links */}
          {/*<div className="header-link-container">
            <NavLink
              exact
              to="/"
              className="header-nav-link"
              activeClassName="header-nav-link-active"
            >
              Matches
            </NavLink>
            <NavLink
              exact
              to="/standings"
              className="header-nav-link"
              activeClassName="header-nav-link-active"
            >
              Standings
            </NavLink>
          </div>*/}
        </div>
        <Switch>
          <Route exact path="/" component={landing} />
          {/*<Route exact path="/" component={matches} />
          <Route exact path="/standings" component={standings} />*/}
          <Redirect from="*" to="/" />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
