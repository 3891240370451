import React from "react";

function Landing() {
  return (
    <div style={{ margin: "1em 3em" }}>
      <h1>5-STAR Golf</h1>
      <h3 style={{ marginTop: "2em" }}>
        Congratulations on 2020 winner, Bryce!
      </h3>
      <h3>See you in 2021...</h3>
    </div>
  );
}

export default Landing;
